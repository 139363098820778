import { React, useState, useEffect, useRef, useLayoutEffect } from 'react';
import sprinkle1 from '../assets/images/sprinkle1.png';
import inflationMoney from '../assets/images/inflationMoney.png';
import inflationGuyHero from '../assets/images/inflationGuyHero.png';
import inflationGuyHeroMob from '../assets/images/inflationGuyHeroMob.png';
import heard1 from '../assets/images/heard1.png';
import heard2 from '../assets/images/heard2.png';
import heard3 from '../assets/images/heard3.png';
import heard4 from '../assets/images/heard4.png';
import heard5 from '../assets/images/heard5.png';
import moneyBook from '../assets/images/moneyBook.png';
import maestroBook from '../assets/images/maestroBook.png';
import podcast from '../assets/images/podcast.png';
import play from '../assets/images/play.png';
import spotifyIcon from '../assets/images/spotifyIcon.png';
import appleIcon from '../assets/images/appleIcon.png';
import p1 from '../assets/images/p1.png';
import p2 from '../assets/images/p2.png';
import p3 from '../assets/images/p3.png';
import p4 from '../assets/images/p4.png';
import p5 from '../assets/images/p5.png';
import p6 from '../assets/images/p6.png';
import p7 from '../assets/images/p7.png';
import { booksData } from '../documents/page-data';
import { individualData } from '../documents/page-data';


import sprinkle2 from '../assets/images/sprinkle2.png';
export default function InflationGuy(props) {
    const [data, setData] = useState([]);
    const [bookData, setBookData] = useState([]);
    const list = [heard1, heard2, heard3, heard4, heard5];
    const  n=30;
    const s=5;
    
    useEffect(() => {
        document.title = "Inflation Guy";
        props.setNav(true);
        setData(individualData);
        setBookData(booksData)
      }, [])

    return(
        <div className='inflation-page'>
          <section className='inflation-guy-hero'>
            <img className='desktop-img' src={inflationGuyHero} alt='img'/>
            <img className='mob-img' src={inflationGuyHeroMob} alt='img'/>
            <h2 className='wg-8 fam-int font-36'>The Hero Your Retirement Needs. <br/> The Hero Your Money Deserves.</h2>
          </section>

          <section className='inflation-guy'>
            <img className='inflationMoney' src={inflationMoney} alt='img'/>
            <div className='inflation-info'>
              <img className='sprinkle1' src={sprinkle1} alt='sprinkle'/>
              <h2 className='col-primary font-32 fam-mul wg-7'>The Inflation Guy</h2>
              <p className='font-18 fam-int wg-4'>His audiences know him as THE ”Inflation Guy.” In the inflation markets he is known as a pioneer. He is considered as the Expert to the experts in the world of inflation markets where true expertise is hard to find. In this podcast the Inflation Guy talks about the hidden tax, the insidious assault on your wealth, and how to defend your money.</p>
              <h4 className='font-24 fam-int wg-4 col-grey'> Have a question? Email <span className='col-primary wg-7'> InflationGuy@enduringinvestments.com</span> </h4>
            </div>
          </section>

          <section className='how heard'>
            <div className='how-title fam-mul wg-7 font-32'>
                You Might Have Seen or Heard Me On
            </div>
            <div className='slider'>
              <div className='how-carousel' style={{ background: 'transparent'}}>
                {[...Array(n)].map((e, i) =>
                  list.map((obj) => 
                    <div className='heard-card'>
                      <img src={obj} alt='img'/>
                    </div>
                  )
                )}
                
              </div>
            </div>
            <div className='mob-heard-card'>
              <div>
                <img src={heard1} alt='img'/>
                <img src={heard2} alt='img'/>
              </div>
              <div>
                <img src={heard3} alt='img'/>
                <img src={heard4} alt='img'/>
              </div>
              <div>
                <img src={heard5} alt='img'/>
              </div>
            </div>
          </section>
              
          
          <div className='animationBook' style={{background: '#ECC9CF'}}>
                {bookData.map((obj, index)  => (
                    <div className='animateBook'>
                        <div className='animateBookLeft'>
                            <div className='book-img'>
                               <img src={obj.image} alt='img'/>
                            </div>
                        </div>
                        <div className='animateBookRight'>
                          <div className='book-text'>
                              <h2 className='fam-mul wg-7 font-32 col-grey'>{obj.title}</h2>
                              <p className='fam-int wg-4 font-18'>{obj.desc}</p>
                          </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='mobAnimationBook' style={{background: '#ECC9CF'}}>
                {bookData.map((obj, index) => (
                    <div className='mobAnimateBook'>
                        <div className='mobAnimateBookLeft'>
                            <div className='mob-book-img'>
                               <img src={obj.image} alt='img'/>
                            </div>
                        </div>
                        <div className='mobAnimateBookRight'>
                          <div className='mob-book-text'>
                              <h2 className='fam-mul wg-7 font-30 col-grey'>{obj.title}</h2>
                              <p className='fam-int wg-4 font-16'>{obj.desc}</p>
                          </div>
                        </div>
                    </div>
                ))}
            </div>

          <section className='reviews'>
            <h2 className='wg-7 fam-mul font-32'>Reviews</h2>
            <div className='review-row'>
                <div className='review-card' style={{background: '#FFF'}}>
                  <div className='star'>
                    {[...Array(s)].map((e, i) =>
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M15.0006 22.825L6.18438 27.76L8.15312 17.85L0.734375 10.99L10.7681 9.8L15.0006 0.625L19.2331 9.8L29.2669 10.99L21.8481 17.85L23.8169 27.76L15.0006 22.825Z" fill="#FACC71"/>
                      </svg>
                    )}
                  </div>
                  <h2 className='col-grey fam-mul font-30 wg-7'>Excellent smart guy!</h2>
                  <p className='font-20 fam-int wg-4'> Very smart guy. Follow him on Twitter for month CPI numbers. Nice too, if you ask him a q he will always seem to answer</p>
                  <h5 className='fam-mul font-20 col-primary wg-7'> nhanco1 </h5>
                </div>
                <div className='review-card' style={{border: '1px solid #2F2F30'}}>
                  <div className='star'>
                    {[...Array(s)].map((e, i) =>
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M15.0006 22.825L6.18438 27.76L8.15312 17.85L0.734375 10.99L10.7681 9.8L15.0006 0.625L19.2331 9.8L29.2669 10.99L21.8481 17.85L23.8169 27.76L15.0006 22.825Z" fill="#FACC71"/>
                      </svg>
                    )}
                  </div>
                  <h2 className='col-grey fam-mul font-30 wg-7'>Educational Perspective on Complex Topics</h2>
                  <p className='font-20 fam-int wg-4'>Thanks for taking the time to share your knowledge and experience. I love this podcast and learning more at a pace I can manage.</p>
                  <h5 className='fam-mul font-20 col-primary wg-7'> PhilipRyan </h5>
                </div>
            </div>
            <div className='review-row ml'>
                <div className='review-card' style={{background: '#ECC9CF'}}>
                  <div className='star'>
                    {[...Array(s)].map((e, i) =>
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M15.0006 22.825L6.18438 27.76L8.15312 17.85L0.734375 10.99L10.7681 9.8L15.0006 0.625L19.2331 9.8L29.2669 10.99L21.8481 17.85L23.8169 27.76L15.0006 22.825Z" fill="#2F2F30"/>
                      </svg>
                    )}
                  </div>
                  <h2 className='col-grey fam-mul font-30 wg-7'>Informative!</h2>
                  <p className='font-20 fam-int wg-4'> Love this podcast <br/> Informative and non bias!</p>
                  <h5 className='fam-mul font-20 col-primary wg-7'> RoxStru </h5>
                </div>
                <div className='review-card' style={{background: '#1769E6', color:'#FFF'}}>
                  <div className='star'>
                    {[...Array(s)].map((e, i) =>
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M15.0006 22.825L6.18438 27.76L8.15312 17.85L0.734375 10.99L10.7681 9.8L15.0006 0.625L19.2331 9.8L29.2669 10.99L21.8481 17.85L23.8169 27.76L15.0006 22.825Z" fill="white"/>
                      </svg>
                    )}
                  </div>
                  <h2 className='fam-mul font-30 wg-7'>Best commentary on inflation</h2>
                  <p className='font-20 fam-int wg-4' style={{color: "rgba(255, 255, 255, 0.80)"}}>It has helped me greatly in terms of understanding the current trajectory of the American economy. Michael has focused on inflation professionally for decades, and his takes are more informed than any commentator I have found.</p>
                  <h5 className='fam-mul font-20 wg-7'> tbjohns10 </h5>
                </div>
            </div>
            <div className='review-row'>
              
            </div>
          </section>

          <section className='podcasts'>
            <div className='head'>
              <h2 className='fam-mul wg-7 font-32'>Recent Podcasts</h2>
              {/* <a href='https://podcasts.apple.com/us/podcast/cents-and-sensibility-the-inflation-guy-podcast/id1583764464'>
                <button className='btn-primary'>
                    View all
                </button></a> */}
            </div>
            <div className='podcast-body'>
          <iframe allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write" className='fam-mul font-28' frameborder="0" height="450" style={{width: "100%", overflow:"hidden", borderRadius:"10px"}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/us/podcast/cents-and-sensibility-the-inflation-guy-podcast/id1583764464"></iframe>

              {/* <div className='podcast-img'>
                <img src={podcast} alt='img'/>
              </div>
              <div className='podcast-text'>
                <div className='podcast-row pt'>
                  <div className='play'>
                    <img src={play} alt='img'/>
                  </div>
                  <div className='podcast-info'>
                    <h2 className='font-20 wg-8 fam-mul'>Ep. 75: Where Are We in the Inflation Tale?</h2>
                    <p className='fam-int font-16 wg-4'>JUL 27, 2023 &emsp; | &emsp; 28 min</p>
                  </div>
                </div>
                <div className='podcast-row'>
                  <div className='play'>
                    <img src={play} alt='img'/>
                  </div>
                  <div className='podcast-info'>
                    <h2 className='font-20 wg-8 fam-mul'>Ep.74: Inflation Folk Remedies</h2>
                    <p className='fam-int font-16 wg-4'>JUL 21, 2023 &emsp; | &emsp; 30 min</p>
                  </div>
                </div>
                <div className='podcast-row'>
                  <div className='play'>
                    <img src={play} alt='img'/>
                  </div>
                  <div className='podcast-info'>
                    <h2 className='font-20 wg-8 fam-mul'>Ep. 73: This Month’s CPI Report - the Longest Journey</h2>
                    <p className='fam-int font-16 wg-4'>JUL 12, 2023 &emsp; | &emsp; 27 min</p>
                  </div>
                </div>
                <div className='podcast-row'>
                  <div className='play'>
                    <img src={play} alt='img'/>
                  </div>
                  <div className='podcast-info'>
                    <h2 className='font-20 wg-8 fam-mul'>Ep. 72: Chapwood Index vs CPI – Which is the Better Inflation Index?</h2>
                    <p className='fam-int font-16 wg-4'>JUL 6, 2023 &emsp; | &emsp; 32 min</p>
                  </div>
                </div>
                <div className='button-bottom'>
                  <a href='https://podcasts.apple.com/us/podcast/cents-and-sensibility-the-inflation-guy-podcast/id1583764464'>
                    <button className='btn-primary'>
                        View all
                    </button></a>
                </div>
              </div> */}
            </div>
          </section>

          <section className='photos'>
            <div className='photo-row'>
              <div className='p1'>
                <img src={p1} alt='img'/>
              </div>
              <div className='p2'>
                <img src={p2} alt='img'/>
              </div>
            </div>
            <div className='photo-row'>
              <div className='p3'>
                <img src={p3} alt='img'/>
              </div>
              <div className='p4'>
                <img src={p4} alt='img'/>
              </div>
              <div className='p5'>
                <img src={p5} alt='img'/>
              </div>
            </div>
            <div className='photo-row'>
              <div className='p6'>
                <img src={p6} alt='img'/>
              </div>
            </div>
          </section>

          <section className='mob-photos'>
            <img src={p1} className='p1' alt='img'/>
            <div className='row'>
              <img src={p2}  alt='img'/>
              <img src={p7} alt='img'/>
            </div>
            <div className='row'>
              <img src={p4} alt='img'/>
              <img src={p5} alt='img'/>
            </div>
            <img src={p6} className='p1' alt='img'/>
          </section>

          <section className='spotify'>
            <div className='spotify-img'>
              <img src={podcast} alt='img'/>
            </div>
            <div className='spotify-info'>
              <h2 className='font-32 fam-mul wg-7 col-grey'>Get the Inflation Guy Podcast</h2>
              <p className='fam-int wg-4 font-30'>Listen on Apple Podcasts and Spotify</p>
              <div className='spotify-icon'>
                <img src={spotifyIcon} alt='img'/>
                <img src={appleIcon} alt='img'/>
              </div>
            </div>
          </section>

        </div>
    )}