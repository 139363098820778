import { React, useState, useLayoutEffect, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import individual1 from '../assets/images/individual1.png';
import individual2 from '../assets/images/individual2.png';
import { individualData } from '../documents/page-data';
import sprinkle2 from '../assets/images/sprinkle2.png';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


export default function Individual(props) {
    const [data, setData] = useState([]);
    
    const main = useRef();
    useEffect(() => {

        const ctx = gsap.context(() => {
         
          const panels = gsap.utils.toArray('.panel');
          console.log('PANELS ', panels.length)
    
          panels.forEach((panel, i) => {
            if (panels[i + 1]) {
              ScrollTrigger.create({
                trigger: main.current,
                start: 'top+=' + 100 * (i + 1) + '%' + ' top',
                end: 'top+=' + 100 * (i + 1) + '%' + ' top',
                // markers: {
                //   startColor: 'white',
                //   endColor: 'white',
                //   indent: 150 * i + 1,
                // },
                id: i,
                onEnter: () => {
                  gsap
                    .timeline()
                    .to(panel, {
                      scale: 0.8,
                      autoAlpha: 0,
                    })
                    .to(
                      panels[i + 1],
                      {
                        scale: 1,
                        autoAlpha: 1,
                      },
                      '<'
                    );
                },
                onEnterBack: () => {
                  gsap
                    .timeline()
                    .to(panel, {
                      scale: 1,
                      autoAlpha: 1,
                    })
                    .to(
                      panels[i + 1],
                      {
                        scale: 0.8,
                        autoAlpha: 0,
                      },
                      '<'
                    );
                },
              });
            }
          });
    
          ScrollTrigger.create({
            trigger: main.current,
            pin: true,
            end: '+=' + panels.length * 90 + '%',
          });
        }, main);
        return () => ctx.revert();
        
      });
    
    useEffect(() => {
        document.title = "Individual | Enduring Investments";
        setData(individualData);
        props.setNav(false);
      }, [])

    return(
        <div className='individual'>
            <section className='office-hero' style={{ minHeight: '90vh'}}>
                <div className='office-hero-text'>
                    <h2 className='hero-title col-primary fam-mul wg-9 font-39'>Individual</h2>
                    <h2 className='hero-subtitle col-grey fam-mul wg-7 font-26'>Managing investments is just half the battle.<br/> Inflation is a war on the value of your money.<br/> Defend your money!</h2>
                    <div className='btn'>
                        <Link to='/contactUs/'><button className='btn-primary font-30'>
                          Defend Now!
                        </button></Link>
                    </div>
                </div>
                <div className='office-hero-img'>
                    <img src={individual1} alt='img'/>
                </div>
            </section>
            <hr flex="true"/>

            <div className='animation' id='animation' ref={main}>
                {data.map((obj, index) => (
                <section className='panel' key={`ind-${index}`}>
                    <div className='animate'>
                        <div className='animate-left'>
                            <div className='animate-top'>
                                <img src={obj.img} className='edu' alt='img' />
                                <img src={sprinkle2} className='sprinkle2' alt='img' />
                            </div>
                            <div className='letter-shadow'>
                                <h2 className='font-small font-36 fam-mul wg-9 col-primary'>{obj.title}</h2>
                                <h2 className='shadow fam-mul wg-9'>{obj.shadow}</h2>
                            </div>
                            {obj.points.map((pnt, index1) => (<p key={`ind-pnt-${index1}`} className='font-18 fam-int wg-4 col-grey'>{pnt} <br /> <br /> </p>))}
                            <h3 className='animate-bottom fam-int wg-6 font-22'> {obj.no} of 2 </h3>
                        </div>
                        <div className='animate-right' style={{ background: obj.bgColor }}>
                            <div className='letter-shadow'>
                                <h2 className='font-33 fam-mul wg-9 col-primary'>How we defend</h2>
                                <h2 className='shadow fam-mul wg-9'>Defend Money</h2>
                            </div>
                            {obj.defendPoints.map((defend, index2) => (<p key={`ind-defend-${index2}`} className='font-18 fam-int wg-4 col-grey'> {defend} <br /> <br /> </p>))}
                        </div>
                    </div>
                </section>
                ))}
            </div>
            
            <div className='mob-animation' id='animation'>
                {data.map((obj, index) => (
                    <section className='mob-animate'>
                        <div className='mob-animate-left'>
                            <div className='mob-animate-top'>
                                <img src={obj.img} className='edu' alt='img' />
                            </div>
                            <div className='letter-shadow'>
                                <h2 className='font-30 fam-mul wg-9 col-primary'>{obj.title}</h2>
                                <h2 className='shadow fam-mul wg-9'>{obj.shadow}</h2>
                            </div>
                            {obj.points.map((pnt, index1) => (<p key={`ins-pnt-${index1}`} className='font-16 fam-int wg-4 col-grey'>{pnt} <br /> <br /> </p>))}
                        </div>
                        <div className='mob-animate-right' style={{ background: obj.bgColor }}>
                            <div className='letter-shadow'>
                                <h2 className='font-30 fam-mul wg-9 col-primary'>How we defend</h2>
                                <h2 className='shadow font-33 fam-mul wg-9'>Defend Money</h2>
                            </div>
                            {obj.defendPoints.map((defend, index2) => (<p key={`ins-defend-${index2}`} className='font-16 fam-int wg-4 col-grey'> {defend} <br /> <br /> </p>))}
                        </div>
                    </section>
                ))}
            </div>

            <section className='office-hero bg-star'>
                <div className='star-text'>
                    <h2 className='star-title col-grey fam-mul wg-7 font-32'>You have better things to do, and frankly they’re more fun than managing money. Let us defend your money.</h2>
                    <p className='star-info fam-int font-18 wg-4'>Contact us today to start a conversation.</p>
                    <div className='btn2'>
                        <Link to='/contactUs/'><button className='btn-primary font-20'>Defend Now!</button></Link>
                    </div>
                </div>
                <div className='office-star-img'>
                    <img src={individual2} alt='starImg'/>
                </div>
            </section>
        </div>
    )
}