import { React, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import hero from '../assets/images/money.png';
import mobHero from '../assets/images/mobMoney.png';
import why from '../assets/images/why.png';
import search from '../assets/images/search.png';
import sprinkle1 from '../assets/images/sprinkle1.png';
import benefits from '../assets/images/benefits.svg';
import mobBenefits from '../assets/images/mobBenefits.png';
import inflationGuy from '../assets/images/inflationGuy.png';
import dollar from '../assets/images/dollar.png';
import cloudGuy from '../assets/images/cloudGuy.png';
import cloud from '../assets/images/cloud.png';
import touch from '../assets/images/touch.png';
import { howData } from "../documents/page-data";

export default function Landing(props) {

  const [howList, setHowList] = useState([]);
  const [firstname, setFirstName] = useState(null);
  const [lastname, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [sendStatus, setSendStatus] = useState("not-submitted");
  const [inflationGuyDoubleClick, setInflationGuyDoubleClick] = useState(false);
  const formRef = useRef();

  const sendMessage = (event) => {
    event.preventDefault();
    if (firstname && lastname && email && message) {
      console.log(firstname, lastname, email, message);
      setSendStatus("submitted");
      fetch(
        "https://w8xrwwelf7.execute-api.us-east-1.amazonaws.com/prod/ei-submit",
        {
          method: "POST",
          body: JSON.stringify({
            firstname: firstname,
            lastname: lastname,
            email: email,
            message: message,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res)
        .then((response) => {
          setSendStatus("sent");
          formRef.current.reset();
        })
        .catch((err) => {
          setSendStatus("error");
          formRef.current.reset();
        });
    }
  };

  const handleInflationGuyClick = () => {
    if (!inflationGuyDoubleClick) {
      setInflationGuyDoubleClick(true);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setInflationGuyDoubleClick(false);
      }, 100); 
    } else {
      setInflationGuyDoubleClick(false);
    }
  };

  useEffect(() => {
    document.title = "Home | Enduring Investments";
    props.toggleNav(false);
    setHowList(howData);
  }, [])
  
  return (
    <>
    <section className='landing'>
      <img className='mob-hero' src={mobHero} alt='mob-landing'/>
        <p className='border fam-mnt'>DEFEND</p>
        <p className='border pd fam-mnt'>YOUR</p>
        <div>
            <p className='fill fam-mnt'> MONEY</p>
            <img src={hero} alt='Money Image'/>
        </div>
        <div className='btn'>
          <Link to='/contactUs/'><button className='btn-primary font-30'>
            Defend Now!
          </button></Link>
        </div>
    </section>

    <section className='why'>
        <div className='why-img'>
          <img className='sprinkle1' src={sprinkle1} alt='sprinkle'/>
          <img className='why-img1' src={why}/>
        </div>
        <div className='why-text'>
          <div className='why-card'>
            <div className='why-title fam-mul wg-7 font-32'>
              Why choose Us?
            </div>
            <h2 className='fam-mul font-30 wg-7'>True Inflation Experts are <br/> Hard to Find</h2>
            <p className='fam-int font-18 wg-4' style={{ paddingBottom: "2rem" }}>You probably have an idea of who you call when you need plumbing, or electrical work, 
              or landscaping. These are experts you have cultivated over time and trust. Who do you 
              go to for inflation expertise? Reading the Wall Street Journal, or your favorite 
              economist? 
              <br/>
              <br/>
              You can figure out a bad plumber because the pipes still leak when he leaves. 
              Can you afford to have a ‘leak’ in your financials because you used the wrong inflation 
              expert?
              <br/>
              <br/>
              <b> We are true inflation experts.</b><br/>
              </p>
              <div>
                <Link to='/contactUs/'><button className='btn-primary'>Defend Now!</button></Link>  
              </div>
            <img className='search' src={search} alt='search'/>
          </div>
          <img className='sprinkle1' src={sprinkle1} alt='sprinkle'/>
        </div>
    </section>

    <section className='benefits'>
      <img className='sprinkle1' src={sprinkle1} alt='sprinkle1'/>
      <img className='sprinkle2' src={sprinkle1} alt='sprinkle1'/>
      <img className='sprinkle3' src={sprinkle1} alt='sprinkle1'/>
      <div className='benefits-text'>
        <h2 className='fam-mul wg-7 font-32'>About Us</h2>
        <p className='fam-int wg-4 font-18'>
        Enduring Investments was founded in 2008 by Michael Ashton, aka The Inflation Guy™ , to offer specialized investment products, research, and advice to clients, focusing on unique strengths and deep institutional experience in inflation-linked markets. We believe that inflation is unlike many other investment risks: investors consciously decide to take equity or interest rate risk, but they are born with inflation risk. It requires an active effort to avoid this risk.
        <br />
        <br />
        For our founder, our partners, and our team, our calling is to engage with individuals, family offices, pension funds, manufacturing companies – anyone who has an inflation or deflation exposure – and design and deploy solutions for them.</p>
        <div className='btn'>
          <Link to='/contactUs/'><button className='btn-primary font-30'>
            Defend Now!
          </button></Link>
        </div>
      </div>
      <div className='benefits-img'>
        <img src={benefits} alt='benefits'></img>
      </div>
      <div className='mob-benefits-img'>
        <img src={mobBenefits} alt='benefits'></img>
      </div>
    </section>

    <section className='how'>
      <div className='how-title fam-mul wg-7 font-32'>
        How we do it
      </div>
      <div className='slider'>
        <div className='how-carousel'>
          {howList.map((obj) => (
            <div className='how-card'>
              <h2 className='how-card-title fam-mul wg-7 font-24'>{obj.title}</h2>
              <div className='how-img'>
                <img src={obj.img} alt='img'/>
              </div>
            </div>
          ))}
          {howList.map((obj) => (
            <div className='how-card'>
              <h2 className='how-card-title fam-mul wg-7 font-24'>{obj.title}</h2>
              <div className='how-img'>
                <img src={obj.img} alt='img'/>
              </div>
            </div>
          ))}
          {howList.map((obj) => (
            <div className='how-card'>
              <h2 className='how-card-title fam-mul wg-7 font-24'>{obj.title}</h2>
              <div className='how-img'>
                <img src={obj.img} alt='img'/>
              </div>
            </div>
          ))}
          {howList.map((obj) => (
            <div className='how-card'>
              <h2 className='how-card-title fam-mul wg-7 font-24'>{obj.title}</h2>
              <div className='how-img'>
                <img src={obj.img} alt='img'/>
              </div>
            </div>
          ))}
          {howList.map((obj) => (
            <div className='how-card'>
              <h2 className='how-card-title fam-mul wg-7 font-24'>{obj.title}</h2>
              <div className='how-img'>
                <img src={obj.img} alt='img'/>
              </div>
            </div>
          ))}
          {howList.map((obj) => (
            <div className='how-card'>
              <h2 className='how-card-title fam-mul wg-7 font-24'>{obj.title}</h2>
              <div className='how-img'>
                <img src={obj.img} alt='img'/>
              </div>
            </div>
          ))}
          {howList.map((obj) => (
            <div className='how-card'>
              <h2 className='how-card-title fam-mul wg-7 font-24'>{obj.title}</h2>
              <div className='how-img'>
                <img src={obj.img} alt='img'/>
              </div>
            </div>
          ))}
          {howList.map((obj) => (
            <div className='how-card'>
              <h2 className='how-card-title fam-mul wg-7 font-24'>{obj.title}</h2>
              <div className='how-img'>
                <img src={obj.img} alt='img'/>
              </div>
            </div>
          ))}
          {howList.map((obj) => (
            <div className='how-card'>
              <h2 className='how-card-title fam-mul wg-7 font-24'>{obj.title}</h2>
              <div className='how-img'>
                <img src={obj.img} alt='img'/>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='mob-cards'>
          {howList.map((obj) => (
            <div className='how-card'>
              <h2 className='how-card-title fam-mul wg-7 font-24'>{obj.title}</h2>
              <div className='how-img'>
                <img src={obj.img} alt='img'/>
              </div>
            </div>
          ))}
      </div>
    </section>

    <section className='cloud'>
      <img className='cloud1' src={cloud} alt='cloud'/>
      <img className='cloud3' src={cloud} alt='cloud'/>
      <img className='cloud4' src={cloud} alt='cloud'/>
      <div className='cloud-text'>
        <img className='cloud2' src={cloud} alt='cloud'/>
        <h2 className='cloud-title fam-mul font-32 wg-7'> Money Doesn’t Work.<br/> People Work.</h2>
        <p className='fam-int wg-4 font-18 cloud-info'>Put your money to work? No, put us to work. You and your money should be relaxing confidently while we live and breathe inflation defense. Do not ignore the biggest long-term risk your investments face.</p>
        <div>
          <Link to='/contactUs/'><button className='btn-primary'>Defend Now!</button></Link>  
        </div>
      </div>
      <div className='cloud-img'>
        <img className='man' src={cloudGuy} alt='img'/>
        <img className='cld' src={cloud} alt='img'/>
      </div>
    </section>

    <section className='about'>
      <img className='sprinkle1' src={sprinkle1} alt='sprinkle1'/>
      <div className='about-card'>
        <div className='about-info'>
          <h2 className='about-title fam-mul font-32 wg-7'>The Inflation Guy</h2>
          <p className='about-detail wg-4 fam-int font-20'>You shouldn't stress on money matters. Trust the voice of smart finances and resourceful investments! Listen to the Inflation Guy Podcast now!</p>
          <div>
            <Link to='/inflationGuy/' onClick={handleInflationGuyClick}><button className='btn-primary fam-mul font-20 wg-7'>Learn More</button></Link>
          </div>
        </div>
        <img className='inflationGuy' src={inflationGuy} alt='img'></img>
      </div>
      <img className='dollar' src={dollar} alt='img'/>
    </section>
          
    <section className='get-in-touch'>
      <div className='form-img'>
        <img src={touch} alt='Get in Touch image'/>
      </div>
      <div className='form-text'>
        <div className='form'>
          <form className="contact-form" ref={formRef} 
            onSubmit={(e) => {
              sendMessage(e);
            }}
          >
            <h3 className="font-32 fam-mul col-grey wg-7">Get in touch</h3>
            <div className='name'>
              <div className='name-50'>
                <label>First Name</label>
                <input className="nca-textfield font-16 fam-int wg-4" required
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      type="text"
                      placeholder="Enter your first name"
                />
              </div>
              <div className='name-50'>
                <label>Last Name</label>
                <input className="nca-textfield font-16 fam-int wg-4" required
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      type="text"
                      placeholder="Enter your last name"
                />
              </div>
            </div>
            <label>Email</label>
            <input className="nca-textfield font-16 fam-int wg-4" required
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type="email"
                  placeholder="Enter your email"
            />
            <label className='textarea-label'>Message</label>
            <textarea className="nca-textfield font-16 fam-int wg-4" required
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              placeholder="Write here...."
              cols="30"
              rows="6"
            ></textarea>
                {sendStatus === "not-submitted" ? (
                  <button className="btn-primary" type="submit">
                    Submit
                  </button>
                ) : sendStatus === "submitted" ? (
                  <p>Sending Message...</p>
                ) : sendStatus === "sent" ? (
                  <p style={{ color: "#00A66A" }}>
                    Your message has been sent!
                    <br /> Our team shall reach out to you soon
                  </p>
                ) : sendStatus === "error" ? (
                  <p style={{ color: "#FA4339" }}>
                    Oops, Something went wrong. Please try again later
                  </p>
                ) : null}
              </form>
        </div>
      </div>
    </section>
    </>
  )
}
