import advice from '../assets/images/advice.png';
import education from '../assets/images/education.png';
import management from '../assets/images/management.png';
import innovation from '../assets/images/innovation.png';
import wealth from '../assets/images/wealth.png';
import systematic from '../assets/images/systematic.png';
import edu from '../assets/images/eduIcon.png';
import adv from '../assets/images/advIcon.png';
import innov from '../assets/images/innovationIcon.png';
import wealthIcon from '../assets/images/wealthIcon.png';
import systemIcon from '../assets/images/systemIcon.png';
import man from '../assets/images/manageIcon.png';
import moneyBook from '../assets/images/moneyBook.png';
import maestroBook from '../assets/images/maestroBook.png';


export const howData = [
    {
        title: "Education",
        img: education
    },
    {
        title: "Advice",
        img: advice
    },
    {
        title: "Management",
        img: management
    },
    {
        title: "Innovation",
        img: innovation
    },
    {
        title: "Wealth Management",
        img: wealth
    },
    {
        title: "Systematic Strategies",
        img: systematic
    }
]

export const familyOfficeData = [
    {
        img: edu,
        title:'Education',
        shadow: 'Education',
        points: ['We routinely deliver training programs to clients and conferences.' , 
                'We can serve as an unparalleled expert resource for clients of our clients.',
                'Inflation can be a topic that produces anxiety in family stakeholders and conflict between income beneficiaries and remaindermen.'],
        no: 1,
        bgColor: 'rgba(236, 201, 207, 0.58)',
        defendPoints: ['Discuss your needs, and the needs of your stakeholders.',
                        'Select one of our standard programs and presentations, or',
                        'We can develop a custom curriculum or communication strategy to suit your needs.'],
        duration: 200       
    },
    {
        img: adv,
        title:'Advice',
        shadow: 'Advice',
        points: ['We can provide Michael Ashton to serve as an outside expert on your investment committee.' , 
                'We can offer an independent evaluation of an existing or proposed new strategy.',
                'We can audit how well the asset strategy immunizes the particular ongoing needs of the family.',
                'We offer advice on how portfolio construction changes in an inflationary environment. Our ongoing research on asset classes and their inflation interactions means we draw from a pool of knowledge no one else has.'],
        no: 2,
        bgColor: 'rgba(250, 204, 113, 0.36)',
        defendPoints: ['Determine whether you require one-off, periodic, project-based, or on-going advice.',
                        'Scope the project, if required.',
                        'We can attend meetings in person or virtually to discuss a project or portfolio or strategy.',
                        'We can sit on a committee, or lead a discussion, or deliver a presentation on our views.'],
        duration: 200        
    },
    {
        img: man,
        title:'Management',
        shadow: 'Management',
        points: ['We offer a wide range of strategies for any risk budget and can tailor exposures specifically to mesh with the existing asset portfolio and specific family requirements and future needs.' , 
                'Select from one or more of our existing strategies, or have us develop a strategy built specifically for you.',
                'We coordinate with you and your/our administrator, lawyers, and accountants to establish the vehicle. We can help indexify a solution if required.'],
        no: 3,
        bgColor: '#D0E1FB',
        defendPoints: ['We assist in opening accounts/negotiating ISDAs if necessary; if the strategy is to run in a separate account you will delegate trading authority to us.',
                        'Test transactions, work and reporting flows, and potentially run a pilot for new strategies.',
                        'Fund the account(s) and begin trading.',
                        'Daily, weekly, monthly, or quarterly P&amp;L reporting and reconciliation available. Full transparency to portfolio holdings at all times.'],
        duration: 200
    },
]

export const institutionData = [
    {
        img: adv,
        title:'Advice',
        shadow: 'Advice',
        points: ['We offer advice on how portfolio construction changes in an inflationary environment. Our ongoing research on asset classes and their inflation interactions means we draw from a pool of knowledge no one else has.',
                'We can offer an independent evaluation of an existing or proposed new strategy.'],
        no: 1,
        bgColor: 'rgba(236, 201, 207, 0.58)',
        defendPoints: ['Determine whether you require one-off, periodic, project-based, or on-going advice.',
                        'Scope the project, if required.',
                        'We can attend meetings in person or virtually to discuss a project or portfolio or strategy.',
                        'We can sit as a committee, or lead a discussion, or deliver a presentation on our views.']        
    },
    {
        img: innov,
        title:'Innovation',
        shadow: 'Innovation',
        points: ['We have developed approaches and assets that help in a range of conditions, not just in inflationary times.',
                'We have developed hedges for specific inflation index exposures; for example Dutch wage inflation, Quebecois retiree inflation, and synthetic option-like payoffs.',
                'We have developed multiple systematic broad commodity index approaches.'],
        no: 2,
        bgColor: '#FDEDCC',
        defendPoints: ['Discuss the target exposure – we can diagnose it, if necessary',
                        'Decompose the exposure into various inflation-space betas (real rates, expectations, specific index basis, non-linearity)',
                        'If necessary, we will conduct primary research to determine how to replicate your particular exposure or need.',
                        'Build, backtest, and evaluate a solution using proper quantitative and econometric backtesting hygiene.']        
    },
    {
        img: man,
        title:'Management',
        shadow: 'Management',
        points: ['We manage commingled vehicles, separate accounts, and can subadvise on 40Act product..' , 
                'We are willing to produce ‘white-label’ solutions that are licensed for use or distribution by our customers.',
                'Select from one or more of our existing strategies, or choose to deploy a strategy built specifically for you.',
                'We coordinate with you and your/our administrator, lawyers, and accountants to establish the vehicle. We can help indexify a solution if required.'],
        no: 3,
        bgColor: '#D0E1FB',
        defendPoints: ['We assist in opening accounts/negotiating ISDAs if necessary; if the strategy is to run in a separate account you will delegate trading authority to us.',
                        'Test transactions, work and reporting flows, and potentially run a pilot for new strategies.',
                        'Fund the account(s) and begin trading.',
                        'Daily, weekly, monthly, or quarterly P&amp;L reporting and reconciliation available. Full transparency to portfolio holdings at all times.']        
    },
]

export const individualData = [
    {
        img: wealthIcon,
        title:'Wealth Management',
        shadow:'Wealth',
        points: ['We manage your assets, in partnership with you.',
                'Our approach is different: we use advanced concepts often applied in large pensions to match your future resources with your future needs rather than simply optimizing your portfolio.',
                'Our focus on inflation means that our approach is designed to secure your future, rather than simply maximizing your dollar wealth in an uncertain inflationary future.'],
        no: 1,
        bgColor: 'rgba(236, 201, 207, 0.58)',
        defendPoints: ['Initial no-obligation call with you to discuss how we work, and your needs.',
                        'We collect information from you about your existing assets, liabilities, incomes, expenses, promises, and dreams, and develop an initial analysis that we share with you on a second no-obligation call.',
                        'Once you engage us, we further refine the plan and help arrange the components of your financial life in ways that make it easy for us to manage them and collaborate with you.',
                        'We regularly review your plan, both for changes in financial markets as well as for events in your life, changes in your goals, etc.']        
    },
    {
        img: systemIcon,
        title:'Systematic Strategies',
        shadow:'Strategies',
        points: ['We can manage some of your assets in strategies specifically designed as inflation-resistant components of your larger plan.',
                'Many investors want just an allocation to inflation but lack (a) a framework for deciding which asset classes (gold? TIPS? Commodities?) and in what weights, and (b) a structured approach to deciding when and how to rebalance within the allocation.',
                'These strategies are standalone components of your overall self-managed plan.',
                'We work hard to implement the strategies in cost-conscious ways: fee drag is a dangerous long-term foe.'],
        no: 2,
        bgColor: '#FDEDCC',
        defendPoints: ['Once you select the strategy you like, we assist you in opening accounts or subaccounts and you delegate trading authority to us over those allocations.',
                        'Some strategies may be available in private fund form.',
                        'You will receive monthly statements, and a manager discussion of the results of the strategy.',
                        'You always have full access to your portfolio positions in separate accounts and there is no locked-in obligation to us. (Some private funds may have liquidity provisions designed to protect other fund investors).']        
    }
]

export const booksData = [
    {
        image: moneyBook,
        title: "What's Wrong with Money?: The Biggest Bubble of All",
        desc: "Discover the captivating insights in 'What's Wrong with Money? Explore the value of money, warning signs of collapse, historical lessons, and investment strategies for various scenarios. Gain expert insight into the erosion of trust in central banks and prepare for the future of money. A must-read for financial awareness"
    },
    {
        image: maestroBook,
        title: "Maestro, My Ass!",
        desc: "Discover the captivating insights in 'What's Wrong with Money? Explore the value of money, warning signs of collapse, historical lessons, and investment strategies for various scenarios. Gain expert insight into the erosion of trust in central banks and prepare for the future of money. A must-read for financial awareness"
    }


]