import { React, useState, useEffect, useRef} from 'react';
import touch from '../assets/images/touch.png';

export default function ContactUs(props) {
    const [firstname, setFirstName] = useState(null);
    const [lastname, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [message, setMessage] = useState(null);
    const [sendStatus, setSendStatus] = useState("not-submitted");
    const formRef = useRef();

    const sendMessage = (event) => {
        event.preventDefault();
        if (firstname && lastname && email && message) {
        console.log(firstname, lastname, email, message);
        setSendStatus("submitted");
        fetch(
            "https://w8xrwwelf7.execute-api.us-east-1.amazonaws.com/prod/ei-submit",
            {
            method: "POST",
            body: JSON.stringify({
                firstname: firstname,
                lastname: lastname,
                email: email,
                message: message,
            }),
            headers: {
                "Content-Type": "application/json",
            },
            }
        )
            .then((res) => res)
            .then((response) => {
            setSendStatus("sent");
            formRef.current.reset();
            })
            .catch((err) => {
            setSendStatus("error");
            formRef.current.reset();
            });
        }
    };
    useEffect(() => {
        document.title = "Contact Us | Enduring Investments";
        props.setNav(false);
      }, [])

    return(
        <div className='contactUs'>
            <section className='contactUs-title'>
                <h2 className='col-primary wg-9 fam-mul font-39'>Get In Touch</h2>
            </section>
            <section className='get-in-touch'>
                <div className='form-img'>
                    <img src={touch} alt='Get in Touch image'/>
                </div>
                <div className='form-text'>
                    <div className='form'>
                    <form className="contact-form" ref={formRef} 
                        onSubmit={(e) => {
                        sendMessage(e);
                        }}
                    >
                        {/* <h3 className="font-32 fam-mul col-grey wg-7">Get in touch</h3> */}
                        <div className='name'>
                        <div className='name-50'>
                            <label>First Name</label>
                            <input className="nca-textfield font-16 fam-int wg-4" required
                                onChange={(e) => {
                                    setFirstName(e.target.value);
                                }}
                                type="text"
                                placeholder="Enter your first name"
                            />
                        </div>
                        <div className='name-50'>
                            <label>Last Name</label>
                            <input className="nca-textfield font-16 fam-int wg-4" required
                                onChange={(e) => {
                                    setLastName(e.target.value);
                                }}
                                type="text"
                                placeholder="Enter your last name"
                            />
                        </div>
                        </div>
                        <label>Email</label>
                        <input className="nca-textfield font-16 fam-int wg-4" required
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            type="email"
                            placeholder="Enter your email"
                        />
                        <label className='textarea-label'>Message</label>
                        <textarea className="nca-textfield font-16 fam-int wg-4" required
                        onChange={(e) => {
                            setMessage(e.target.value);
                        }}
                        placeholder="Write here...."
                        cols="30"
                        rows="6"
                        ></textarea>
                            {sendStatus === "not-submitted" ? (
                            <button className="btn-primary" type="submit">
                                Submit
                            </button>
                            ) : sendStatus === "submitted" ? (
                            <p>Sending Message...</p>
                            ) : sendStatus === "sent" ? (
                            <p style={{ color: "#00A66A" }}>
                                Your message has been sent!
                                <br /> Our team shall reach out to you soon
                            </p>
                            ) : sendStatus === "error" ? (
                            <p style={{ color: "#FA4339" }}>
                                Oops, Something went wrong. Please try again later
                            </p>
                            ) : null}
                        </form>
                    </div>
                </div>
            </section>
        </div>
    )}