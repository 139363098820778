import { React, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import linkedIn from "../assets/images/linkedIn.png";
import x from "../assets/images/x.png";
import Modal from "react-modal";
import pdf from "../documents/EI-CRS.pdf";

export default function Footer() {
  const [DisclaimerModalIsOpen, setIsOpenDisclaimer] = useState(false);
  const [PrivacyModalIsOpen, setIsOpenPrivacy] = useState(false);
  function openPDF() {
    window.open(pdf);
  }

  function openDisclaimerModal() {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    setIsOpenDisclaimer(true);
  }

  function closeDisclaimerModal() {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
    setIsOpenDisclaimer(false);
  }

  function openPrivacyModal() {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    setIsOpenPrivacy(true);
  }

  function closePrivacyModal() {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
    setIsOpenPrivacy(false);
  }

  const location = useLocation();
  const [familyOfficeDoubleClick, setFamilyOfficeDoubleClick] = useState(false);
  const [institutionDoubleClick, setInstitutionDoubleClick] = useState(false);
  const [individualDoubleClick, setIndividualDoubleClick] = useState(false);
  const [inflationGuyDoubleClick, setInflationGuyDoubleClick] = useState(false);

  const handleLogoClick = (e) => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleFamilyOfficeClick = () => {
    if (!familyOfficeDoubleClick) {
      setFamilyOfficeDoubleClick(true);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setFamilyOfficeDoubleClick(false);
      }, 200);
    } else {
      setFamilyOfficeDoubleClick(false);
    }
  };

  const handleInstitutionClick = () => {
    if (!institutionDoubleClick) {
      setInstitutionDoubleClick(true);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setInstitutionDoubleClick(false);
      }, 200);
    } else {
      setInstitutionDoubleClick(false);
    }
  };

  const handleIndividualClick = () => {
    if (!individualDoubleClick) {
      setIndividualDoubleClick(true);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setIndividualDoubleClick(false);
      }, 300);
    } else {
      setIndividualDoubleClick(false);
    }
  };

  const handleInflationGuyClick = () => {
    if (!inflationGuyDoubleClick) {
      setInflationGuyDoubleClick(true);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setInflationGuyDoubleClick(false);
      }, 400);
    } else {
      setInflationGuyDoubleClick(false);
    }
  };

  return (
    <section className="footer">
      <div className="footer-top">
        <Link className="logo" to="/" onClick={handleLogoClick}>
          <img src={logo} alt="logo" />
        </Link>
        <div className="footer-list font-20 fam-int wg-4">
          <Link
            to="/family-office/"
            className="nav-links"
            onClick={handleFamilyOfficeClick}
          >
            Family Office
          </Link>
          <Link
            to="/institution/"
            className="nav-links"
            onClick={handleInstitutionClick}
          >
            Institution
          </Link>
          <Link
            to="/individual/"
            className="nav-links"
            onClick={handleIndividualClick}
          >
            Individual
          </Link>
          {/* <a href="https://inflationguy.blog/" className="nav-links">
            Blog
          </a> */}
          <Link
            to="/inflationGuy/"
            className="nav-links"
            onClick={handleInflationGuyClick}
          >
            The Inflation Guy
          </Link>
        </div>
      </div>
      <div className="footer-bottom">
        <h2 className="font-32 fam-mul wg-7">Stay Connected</h2>
        <div className="social-media">
          <a href="https://www.linkedin.com/in/michael-ashton-cfa-735875/">
            <img src={linkedIn} alt="logo" />
          </a>
          <a href="https://twitter.com/inflation_guy?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor">
            <img src={x} alt="logo" />
          </a>
        </div>
      </div>
      <div className="footer-privacy">
        <h2 className="font-14 fam-int wg-4">
          Copyright © Enduring Investments, LLC 2023
        </h2>
        <div className="policies fam-int">
          <button onClick={openPrivacyModal}>Privacy</button>|
          <button className="col-grey" onClick={openDisclaimerModal}>
            Disclaimer
          </button>
          |
          <button className="col-grey" onClick={openPDF}>
            ADV Part 3
          </button>
        </div>
      </div>
      <Modal
        isOpen={DisclaimerModalIsOpen}
        onRequestClose={closeDisclaimerModal}
        contentLabel="Example Modal"
      >
        <div class="modal-header">
          <h1 className="fam-int">Legal Disclaimer</h1>
          <span class="close" onClick={closeDisclaimerModal}>
            &times;
          </span>
        </div>
        <div class="modal-body">
          <p>
            Welcome to the Website (the “Website”) for Enduring Investments LLC
            (“ENDURING”). This Website provides you with general information
            about ENDURING
          </p>
          <p>
            PLEASE READ THESE TERMS OF USE CAREFULLY. By accessing and using
            this Website, you acknowledge that you have read, understand, and
            agree to be bound by all of the terms and conditions set forth
            below. We reserve the right to amend these terms of use from time to
            time by posting the amended terms on this Website.
          </p>
          <h3>Access to the Website:</h3>
          <p>
            The Website is made available to all persons aged 18 or older who
            agree to be bound by the terms and conditions set forth in these
            terms of use. You agree not to modify, reproduce, distribute, rent,
            lease, loan, sell, or create derivative works based upon the
            materials on this Website, or post any content from this Website to
            newsgroups, mail lists or electronic bulletin boards, without the
            prior written consent of an authorized representative of ENDURING.
          </p>
          <h3>No Offer, Investment Advice, or Performance Guarantees:</h3>
          <p>
            Neither the information nor any opinion expressed on this Website
            constitutes an offer by ENDURING to buy or sell any securities or
            financial instruments, or to provide any investment advice or
            service. The services, securities and financial instruments
            described on this Website may not be available to or suitable for
            you, and not all strategies are appropriate at all times. The value
            and income of any of the securities or financial instruments
            mentioned on this Website can fall as well as rise, and an investor
            may get back less than he or she invested. Foreigncurrency
            denominated securities and financial instruments are subject to
            fluctuations in exchange rates that could have a positive, or
            adverse, effect on the value, price or income of such securities and
            financial instruments. Past performance is not necessarily a guide
            to future performance. Independent advice should be sought in all
            cases.
          </p>
          <h3>Responsible Behavior:</h3>
          <p>
            In addition to the obligations set forth above, you agree not to
            access or use this Website to: a) reproduce, duplicate, copy, sell,
            trade, resell or exploit for any commercial purposes, any portion of
            this Website, use of this Website, or access to this Website; b)
            impersonate any person or entity or falsely state or otherwise
            misrepresent your affiliation with a person or entity; c) forge
            headers or otherwise manipulate identifiers in order to disguise the
            origin of any content transmitted on this Website; d) make available
            any content that you do not have a right to make available under any
            law or under contractual or fiduciary relationships; e) make
            available any content that infringes any patent, trademark, trade
            secret, copyright or other proprietary rights of any party; f) make
            available any content that is unlawful, harmful, threatening,
            tortious, defamatory, obscene, invasive of another's privacy, or
            otherwise objectionable; g) make available any unsolicited or
            unauthorized advertising, promotional materials, "junk mail,"
            "spam," "chain letters," "pyramid schemes," or any other form of
            solicitation; h) make available any material that contains software
            viruses or any other computer code, files or programs designed to
            interrupt, destroy or limit the functionality of any computer
            software or hardware or telecommunications equipment; i) interfere
            with or disrupt this Website or servers or networks connected to
            this Website, or disobey any requirements, procedures, policies or
            regulations of networks connected to this Website; j) intercept,
            collect or store personal data about current or former clients of
            ENDURING or any other visitors to this Website; or k) violate any
            applicable local, state, national or international laws or
            regulations.
          </p>
          <h3>Links to Third Party Websites:</h3>
          <p>
            This Website may contain links to other Websites, including links to
            the Websites of companies that provide related information, products
            and services. These links are solely for the convenience of visitors
            to this Website, and the inclusion of such links does not
            necessarily imply an affiliation, sponsorship or endorsement.
            ENDURING makes no representations whatsoever regarding such third
            party Websites. You should review the privacy policies of other
            Websites carefully before providing any information to such
            Websites. We are not responsible for the content or availability of
            third party Websites, and shall not be responsible or liable for any
            information, opinions, advice, products, or services available on or
            through such third party Websites.
          </p>
          <h3>Links to This Website:</h3>
          <p>
            We ask that you notify us in advance of your intention to link to
            the public portion of this Website by emailing us at{" "}
            <b>admin@EnduringInvestments.com</b>. You may not (i) hyperlink to
            the private portion of this Website or any of the pages on the
            private portion of this Website from another Website, (ii) display
            this Website in frames, or (iii) display any of the content found on
            this Website via inline links, without express written permission
            from an authorized representative of ENDURING.
          </p>
          <h3>Our Rights in This Website:</h3>
          <p>
            ENDURING and our suppliers reserve all right, title and interest in
            and to this Website and all materials, information, and compilations
            of information available on or through this Website, as well as the
            domain name, design, layout, and "look and feel" of this Website,
            the trademarks, service marks, and trade dress related thereto, and
            all other intellectual property and other proprietary rights
            embodied therein and appurtenant thereto. You agree that you will
            have no rights in or to any of the foregoing, express or implied,
            other than the rights expressly granted in these terms of use, or
            otherwise expressly granted to you in writing by an authorized
            representative of ENDURING.
          </p>
          <h3>Disclaimer of Representations and Warranties:</h3>
          <p>
            THIS WEBSITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.
            ENDURING MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER
            EXPRESS, IMPLIED, OR STATUTORY, REGARDING YOUR ACCESS TO OR USE OF
            THIS WEBSITE, OR THE PRODUCTS, SERVICES OR INFORMATION DESCRIBED ON
            OR AVAILABLE THROUGH THIS WEBSITE. WITHOUT LIMITING THE FOREGOING,
            WE EXPRESSLY DISCLAIM ANY AND ALL REPRESENTATIONS AND WARRANTIES OF
            ACCURACY, COMPLETENESS, TIMELINESS, MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT OF THIRD-PARTY
            RIGHTS. THIS WEBSITE INCLUDES FACTS, VIEWS AND OPINIONS OF
            INDIVIDUALS AND ORGANIZATIONS DEEMED OF INTEREST. NOTHING CONTAINED
            ON THIS WEBSITE SHALL BE CONSTRUED AS AN ENDORSEMENT OF THESE VIEWS
            OR OPINIONS.
            <br />
            <br />
            Reviews relating to Inflation Guy Podcasts should not be construed
            as an endorsement or recommendation of Enduring Investments LLC or
            to invest in any particular investment product managed by Enduring
            Investments LLC or its affiliates. No cash or non-cash compensation
            was provided by of Enduring Investments LLC in connection with the
            review or the disclosure of the review on this website. Because of
            Enduring Investments LLC may be subject to certain material
            conflicts of interest resulting from its relationship with clients
            or investors, Enduring Investments LLC has implemented procedures
            designed to mitigate these potential conflicts of interest.
          </p>
          <h3>Limitations of Liability:</h3>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            ENDURING OR OUR AFFILIATES, DIRECTORS, OFFICERS, AGENTS, EMPLOYEES
            OR LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY INDIRECT,
            CONSEQUENTIAL, SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE, OR SIMILAR
            DAMAGES (INCLUDING LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA)
            ARISING OUT OF OR RELATED TO THIS WEBSITE OR YOUR USE OF THIS
            WEBSITE, OR FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE
            ON THE INFORMATION PROVIDED ON OR AVAILABLE THROUGH THIS WEBSITE,
            REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT OR
            OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
        </div>
        <div class="modal-footer">
          <button className="btn-primary" onClick={closeDisclaimerModal}>
            Close
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={PrivacyModalIsOpen}
        onRequestClose={closePrivacyModal}
        contentLabel="Example Modal"
      >
        <div class="modal-header">
          <h1 className="fam-int">Privacy Policy</h1>
          <span class="close" onClick={closePrivacyModal}>
            &times;
          </span>
        </div>
        <div class="modal-body">
          <p>
            Except as otherwise agreed in writing or as required by law, the
            Firm will keep confidential all information concerning Client’s
            identity, financial affairs and/or investments. The Firm does not
            sell or rent Client information. The Firm will use certain
            confidential Client information to conduct business with Client; to
            develop or enhance its products and services; to understand the
            financial needs of Client so that the Firm can provide Client with
            quality products and superior service; and to protect and administer
            Client’s records, accounts and funds. The Firm does not disclose
            nonpublic personal information about Client to nonaffiliated third
            parties or to affiliated entities, except as permitted or required
            by law. For example, the Firm may share nonpublic personal
            information in the following situations:
          </p>
          <ul>
            <li>
              To service providers in connection with the administration and
              servicing of the Firm, which may include attorneys, accountants,
              auditors and other professionals. The Firm may also share
              information in connection with the servicing or processing of
              client transactions;
            </li>
            <li>
              To affiliated companies in order to provide you with ongoing
              personal advice and assistance with respect to the products and
              services you have purchased through the Firm and to introduce you
              to other products and services that may be of value to you;
            </li>
            <li>
              To respond to a subpoena or court order, judicial process or
              regulatory authorities;
            </li>
            <li>
              To protect against fraud, unauthorized transactions (such as money
              laundering), claims or other liabilities; and
            </li>
            <li>
              Upon consent of an investor to release such information, including
              authorization to disclose such information to persons acting in a
              fiduciary or representative capacity on behalf of the investor.
            </li>
          </ul>
          <p>
            The Firm’s policy is to require that all employees, financial
            professionals and companies providing services on its behalf keep
            client information confidential.
          </p>
          <p>
            The Firm maintains safeguards that comply with federal standards to
            protect investor information. The Firm restricts access to the
            personal and account information of investors to those employees who
            need to know that information in the course of their job
            responsibilities. Third parties with whom the Firm shares investor
            information must agree to follow appropriate standards of security
            and confidentiality.{" "}
          </p>
          <p>
            The Firm’s privacy policy applies to both current and former
            investors. The Firm may disclose nonpublic personal information
            about a former investor to the same extent as for a current
            investor.
          </p>
          <p>
            The Firm may make changes to its privacy policy in the future. The
            Firm will not make any change affecting you without first sending
            you a revised privacy policy describing the change.{" "}
          </p>
        </div>
        <div class="modal-footer">
          <button className="btn-primary" onClick={closePrivacyModal}>
            Close
          </button>
        </div>
      </Modal>
    </section>
  );
}
