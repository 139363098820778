import { React, useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Landing from '../pages/Landing';
import "../styles/main.css";
import ScrollToTop from './ScrollToTop';
import FamilyOffice from '../pages/FamilyOffice';
import Institution from '../pages/Institution';
import Individual from '../pages/Individual';
import InflationGuy from '../pages/InflationGuy';
import ContactUs from '../pages/ContactUs';

export default function App() {
  const [navMode, setNavMode] = useState("");

  const toggleNavMode = (mode) => {
    if (mode) {
      setNavMode("nav-white")
    } else {
      setNavMode("nav-black")
    }
  }
  
  return (
    <>
      <BrowserRouter>
        <Header nav={navMode} />
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Landing toggleNav={toggleNavMode} />} />
          <Route path='/family-office/' element={<FamilyOffice setNav={toggleNavMode} />} />
          <Route path='/institution/' element={<Institution setNav={toggleNavMode} />} />
          <Route path='/individual/' element={<Individual setNav={toggleNavMode} />} />
          <Route path='/inflationGuy/' element={<InflationGuy setNav={toggleNavMode} />} />
          <Route path='/contactUs/' element={<ContactUs setNav={toggleNavMode} />} />
          {/*<Route path='/product/website/' element={<Products product='website' setNav={toggleNavMode} />} />
          <Route path='/case-studies/' element={<CaseStudies setNav={toggleNavMode} />} />
          <Route path='/pricing/' element={<Pricing setNav={toggleNavMode} />} />
          <Route path='/about/' element={<About setNav={toggleNavMode} />} />
          <Route path='/privacypolicy/' element={<PrivacyPolicy setNav={toggleNavMode}/>} /> */}
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  )
}
