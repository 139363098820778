import { React, useState, useRef } from 'react';
import { Link, Navigate, useLocation } from "react-router-dom";
import logo from "../assets/images/logoText.svg";

export default function Header(props) {
  const navRef = useRef();
  const {pathname} = useLocation();
  const navColor = (props.nav ==  'nav-white' ? 'transparent' : '#F8F6F1')

  const addNav = () => {
    if(!navRef.current.classList.contains("open-nav"))
      navRef.current.classList.toggle("open-nav");
  }

  const removeNav = () => {
    if(navRef.current.classList.contains("open-nav"))
      navRef.current.classList.toggle("open-nav");
  }

  return (
    <header className={`navigation wg-7 ${props.nav}`} style={{background: navColor}}>
      <div className='mob-nav-logo'>
          <Link to='/'>
            <img src={logo} alt='Logo'/>
          </Link>
        </div>
      <div className="navigation-wrapper" ref={navRef}>
        <div id='logo'>
          <Link to='/'>
            <img src={logo} alt='Logo' sizes={{ width:40, height: 40}}/>
          </Link>
        </div>
        <div className="nav-top">
          <div className="mob-nav-logo">
            {/* <Logo type="logo-compact" size={{ widht: 40, height: 40 }} /> */}
          <img src={logo} alt='Logo'/>
            <span className="material-symbols-rounded close-nav-ic" onClick={() => { removeNav();}}>
              close
            </span>
          </div>
          <ul className="routes main-head
           wg-4">
           <li className="main-links"><Link to="/family-office/" onClick={() => { removeNav();}} className={`nav-links ${(pathname === '/family-office/') ? 'active' : ''}`}>Family Office</Link></li>
            <li className="main-links"><Link to="/institution/" onClick={() => { removeNav();}} className={`nav-links ${(pathname === '/institution/') ? 'active' : ''}`}>Institution</Link></li>
            <li className="main-links"><Link to="/individual/" onClick={() => { removeNav();}} className={`nav-links ${(pathname === '/individual/') ? 'active' : ''}`}>Individual</Link></li>
            {/* <li className="main-links"><a href="https://inflationguy.blog/" onClick={() => { removeNav();}} className={`nav-links`}>Blog</a></li> */}
            {/* <li className="main-links"><Link to=""  className={`nav-links ${(pathname === '/learning/') ? 'active' : ''}`}>Learning</Link></li> */}
          </ul>
        </div>
        <div className="nav-bottom wg-7 defend font-16">
          <Link to="/inflationGuy/" onClick={() => { removeNav();}} className={'nav-links'}><button className='btn-primary' onClick={() => { removeNav();}}>Inflation Guy</button></Link>
          <Link to='/contactUs/'><button className='btn-primary' onClick={() => { removeNav();}}>Defend Now!</button></Link>
        </div>
        <div className="nav-bottom wg-7 back font-16">
          <a href="https://inflationguy.blog/"className='btn-primary col-primary blog-btn' onClick={() => { removeNav();}}>Blog</a>
          <Link to='/'>
            <button className='btn-primary' onClick={() => { removeNav();}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path d="M7.828 11.4997H20V13.4997H7.828L13.192 18.8637L11.778 20.2777L4 12.4997L11.778 4.72168L13.192 6.13568L7.828 11.4997Z" fill="white"/>
              </svg>
              Back to Website
            </button>
          </Link>
        </div>
      </div>
      <div className='nav-btn'>
          <div className="nav-ham" onClick={addNav} id={props.nav}>
            <svg width="45" height="45" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.1998 12H19.1998" stroke="black" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round" />
              <path d="M19.1998 6.85693H4.7998" stroke="black" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round" />
              <path d="M19.1998 17.1428H4.7998" stroke="black" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round" />
            </svg>
          </div>
      </div>
    </header>
  )
}
